html {
    margin: 0;
    min-height: 100%;
}

#background {
    position: fixed;
    width: 100%;
    height: 100%;
    object-fit: cover;
    top: 0;
    left: 0;
    filter: brightness(1.2);
    z-index: -2;
}

.headerBar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    text-decoration: none;
    position: fixed;
    top: 0;
    z-index: 9;
}

.leftBarContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 16em;
}

.logoContainer {
    margin: 0.7em 0 0.7em 2.5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 8.8em;
    text-decoration: none;

}

.logoHakurei {
    color: white;
    text-transform: uppercase;
    font-size: 1.2em;
    width: 5.2em;
    letter-spacing: 0.1em;
    font-family: "melancholy";

}

.logoImageContainer {
    display: flex;
    align-items: center;
    margin-right: 0.5em;
}

.logoImage {
    width: 2em;
}

#audioController {
    width: 35px;
    height: 35px;
    cursor: pointer;
    border-radius: 100%;
    margin: 0 1em 0 2em;
}

#audioController:hover {
    filter: brightness(0.8);
}

.sourcesContainer {
    color: white;
    position: absolute;
    left: 18em;
}

.link {
    border: none;
    background: none;
    color: white;
    cursor: pointer;
    font-size: 1.3em;
    border: 3px solid;
    border-radius: 1em;
    position: relative;
    font-family: "izayoi";
    animation: colorChange 0.75s infinite;
    animation-direction: alternate;
}


.link:hover {
    background: white;
}

.dropdownContent {
    position: absolute;
    left: -18em;
    top: 100%;
    background-color: rgba(0, 0, 0, 0.9);
    padding: 0.75rem;
    border-radius: 0.25rem;
    display: none;
    flex-direction: column;
    width: 30em;
    text-align: center;
    color: white;
}

.aboutCopyright {
    font-size: 1.2em;
    color: rgb(255, 139, 139);
}

.sourcesLink {
    position: relative;
    color: rgb(98, 148, 255);
    word-wrap: break-word;
}

.dm {
    font-size: 1.2em;
    color: rgb(211, 247, 164);
}

.rightBarContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.rightBarStuff {
    position: relative;
    color: white;
    width: 27em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-right: 2.5vw;
}

.separator {
    width: 2.5em;
    height: 2.5em;
    filter: hue-rotate(140deg);
}

.about,
.characters,
.music,
.contact {
    font-size: 1.1em;
    text-decoration: none;
    color: rgb(221, 221, 221);
    font-family: "izayoi";
    position: relative;
    padding: 0 6px;
}

.about:after,
.characters:after,
.music:after,
.contact:after {
    content: "";
    position: absolute;
    background-image: linear-gradient(to right, rgb(0, 217, 255), rgb(0, 4, 255));
    height: 2px;
    width: 0%;
    left: 0;
    bottom: -2px;
    transition: 0.3s;
}

.about:hover:after,
.characters:hover:after,
.music:hover:after,
.contact:hover:after {
    width: 100%;
}

.about:hover,
.characters:hover,
.music:hover,
.contact:hover {
    text-shadow: 0 0 5px rgb(59, 210, 230), 0 0 12px rgb(59, 210, 230), 0 0 20px rgb(59, 210, 230);
    color: white;
}

.rightBarStuff.show {
    display: flex;
    position: absolute;
    flex-direction: column;
    z-index: 4;
    padding: 1.5em 0;
    top: 3.4em;
    width: 10em;
    right: -0.42em;
    background: rgba(0, 0, 0, 0.9);
}

.menuHamburger {
    position: relative;
    display: none;
    justify-content: center;
    align-items: center;
    right: 2vw;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: all .5s ease-in-out;
}


.menuHamburgerBtn {
    width: 25px;
    height: 2.5px;
    background: #fff;
    border-radius: 2px;
    transition: all .25s ease-in-out;
}

.menuHamburgerBtn::before,
.menuHamburgerBtn::after {
    content: "";
    position: absolute;
    width: 25px;
    height: 2.5px;
    background: #fff;
    border-radius: 2px;
    transition: all .25s ease-in-out;
}

.menuHamburgerBtn::before {
    transform: translateY(-10px);
}

.menuHamburgerBtn::after {
    transform: translateY(10px);
}

.menuHamburger.open .menuHamburgerBtn {
    transform: translateX(-50px);
    background: transparent;
}

.menuHamburger.open .menuHamburgerBtn:before {
    transform: rotate(45deg) translate(35px, -35px);

}

.menuHamburger.open .menuHamburgerBtn:after {
    transform: rotate(-45deg) translate(35px, 35px);

}

.layer1 {
    position: relative;
}

.layer1BgSources {
    font-size: 0.9em;
    position: absolute;
    bottom: -19em;
    left: 50%;
    transform: translateX(-50%);
    background: black;
    padding: 7px;
    border-radius: 1em;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.layer1BgSource1 {
    color: white;

}

.layer1BgSource2 {
    color: white;
}


.layer1Container {
    width: 85%;
    height: 100%;
    border-radius: 1.5em;
    /*margin: 9em auto 0 auto;*/
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    box-shadow: 0 0 2em rgb(69, 69, 69);
}

.layer1Left {
    color: white;
    margin: 3em 2em 3em 5em;
    /*width: 40vw;*/
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
}

.greetings {
    font-size: 2.2em;
    text-shadow: 0 0 0.8em rgb(255, 255, 255);
    text-align: center;
    font-family: "kawashiro";

}

.description {
    margin-top: 1em;
    font-size: 1.1em;
    line-height: 1.4em;
    text-align: justify;
}

.donateNowContainer {
    margin-top: 3em;
}

.donateNow {
    color: white;
    border: 0.2em solid white;
    border-radius: 2em;
    width: 10em;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    font-size: 1.2em;
    text-decoration: none;
    background-image: linear-gradient(rgba(250, 0, 0, 0.5), rgba(107, 39, 0, 0.5));
    transition: 0.3s;
    font-family: "kawashiro";
}

.donateNow:hover {
    transform: translateY(-3%);
    background: linear-gradient(rgba(250, 0, 0, 0.7), rgba(107, 39, 0, 0.7));
    box-shadow: 0 0 0.6em white;

}

.layer1Right {
    width: 50vw;
    margin: 3em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.layer1Image {
    width: 35vw;

}

.layer1Caption {
    color: white;
    margin-top: 0.5em;
    font-size: 1.1em;
}

.layer1Source {
    color: white;
}

.layer2Bg {
    background-image: linear-gradient(rgb(105, 14, 14), rgb(56, 9, 9));
    margin-top: 18em;
}

.layer2Content {
    padding-top: 1em;
}

.layer2Heading {
    font-size: 2em;
    text-align: center;
    color: white;
    margin: 0.5em 1em 1.5em 1em;
    font-family: "kamishirasawa";

}

.layer2InfoContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 2.5em;
    flex-wrap: wrap;
}

.gameContainer,
.fanartContainer,
.musicContainer,
.heyMisterContainer,
.fumoContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 1 0px;
}

.gameImage,
.fanartImage,
.musicVideo,
.heyMisterImage,
.fumoImage {
    width: 12em;
    height: 14em;
    object-fit: cover;
    border: 0.3em solid rgb(255, 255, 255);
    border-radius: 1.5em;
    box-shadow: 0 10px 30px black;
    transition: 0.3s;
    margin: -0.5em -1em -1em -1em;
}

.gameImage:hover,
.fanartImage:hover,
.musicVideo:hover,
.heyMisterImage:hover,
.fumoImage:hover {
    transform: scale(1.05);
    box-shadow: 0 0 30px rgb(2, 173, 164);
}

.fanartSource {
    color: white;
    text-align: center;
    font-size: 0.8em;
    margin-top: -2em;
}

.linkI {
    margin: 1em 2em 0 2em;
    border-radius: 1.5em;
}

.gameSubtext,
.fanartSubtext,
.musicSubtext,
.heyMisterSubtext,
.fumoSubtext {
    text-align: center;
    color: white;
    font-size: 1.7em;
    margin: 1em 0 1em 0;
    font-family: "lunatic";
    z-index: 2;
}

.stuff {
    font-style: italic;
}

.layer3 {
    background-size: cover;
    object-fit: cover;
    background-attachment: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    filter: brightness(1.1);
}

.layer3BgSource {
    background: black;
    border-radius: 1em;
    position: absolute;
    padding: 7px;
    color: white;
    display: block;
    bottom: 5px;
}

.layer3Text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: white;
    border-radius: 1em;
    text-shadow: 0 0 1em black, 0 0 2em black;
    font-family: "hakugyokurou";
    letter-spacing: 0.1em;
    margin: 11em 0;
}

.layer3Quote {
    font-size: 3em;
    text-align: center;
    font-style: italic;
}

.layer3QuoteFrom {
    text-align: center;
    margin-top: 2em;
    font-size: 2em;
}

.layer4Bg {
    background-image: linear-gradient(rgb(105, 14, 14), rgb(56, 9, 9));
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2.5em 0;
}

.layer4Content {
    background: rgb(32, 32, 32);
    width: 60em;
    padding: 2em 0;
    border-radius: 0.5em;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0 2em;

}

.layer4Text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
    padding: 0 2em 0 4em;
    line-height: 1.3em;
}

.layer4TextHeading {
    font-size: 1.1em;
    font-family: "sunflower";
    font-weight: bold;
}

.layer4TextSubtext {
    margin-top: 3px;
}


.layer4ContactContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5em 0 3em;
}

.layer4Contact {
    color: white;
    border: 0.15em solid white;
    background-image: linear-gradient(rgba(0, 171, 194, 0.5), rgba(1, 68, 68, 0.5));
    border-radius: 0.4em;
    padding: 8px 30px;
    text-align: center;
    text-decoration: none;
    font-family: "kawashiro";
}

.layer4Contact:hover {
    background-image: linear-gradient(rgba(0, 171, 194, 0.7), rgba(1, 68, 68, 0.7));
}

@media (max-width: 1100px) {
    .layer1Container {
        flex-direction: column;
    }

    .layer1Left {
        margin: 4em auto 0 auto;
        width: 65vw;
    }

    .layer1Right {
        margin-top: 4em;
    }

    .layer1Image {
        width: 60vw;
    }

    .layer1Caption {
        font-size: 2vw;

    }
}

@media (max-width: 940px) {
    .layer4Content {
        flex-direction: column;
    }

    .layer4TextHeading {
        text-align: center;
    }

    .layer4ContactContainer {
        margin: 1em auto 0 auto;
    }

    .layer4Text {
        align-items: center;
        padding: 0;
        margin: 0 1em;
    }

    .layer4TextSubtext {
        text-align: center;
    }
}

@media (max-width: 860px) {
    .rightBarStuff {
        display: none;
    }

    .menuHamburger {
        display: flex;
    }

    .headerBar {
        width: 100vw;
    }

    .layer1Caption,
    .layer1Source {
        font-size: 0.9em;
        text-align: center;
    }

    .rightBarContainer {
        display: flex;
        justify-content: flex-end;
        margin-right: 7vw;
    }

    .layer1Image {
        width: 70vw;
    }
}

@media (min-width: 861px) {
    .rightBarStuff {
        position: relative;
        color: white;
        width: 27em;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-right: 2.6vw;
    }

    .rightBarStuff.show {
        display: flex;
        position: relative;
        flex-direction: row;
        z-index: 4;
        height: 0em;
        margin-top: -6.6em;
        width: 27em;
        background: transparent;
    }
}

@media (max-width: 500px) {
    #audioController {
        display: none;
    }

    .sourcesContainer {
        top: 3.5em;
        left: 0
    }

    .dropdownContent {
        left: 0;
        width: 17em;
    }

    .aboutCopyright {
        display: none;
    }

    .dm {
        font-size: 0.8em;
    }

    .sourceC,
    .sourcesLink {
        font-size: 0.75em;
    }
}

@keyframes colorChange {
    0% {
        background: rgb(255, 109, 109);
    }

    100% {
        background: rgb(143, 0, 55);
    }
}

@font-face {
    font-family: 'Audiowide';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/audiowide/v16/l7gdbjpo0cum0ckerWCdmA_OIxo.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
    font-family: 'Audiowide';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/audiowide/v16/l7gdbjpo0cum0ckerWCdlg_O.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,700);

body {
    background: #ff564c;
    padding: 0;
    margin: 0;
}

@keyframes harlem {
    0% {
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
    }

    20% {
        transform: scale(1.03, 1);
        -webkit-transform: scale(1.03, 1);
        -moz-transform: scale(1.03, 1);
        -o-transform: scale(1.03, 1);
        -ms-transform: scale(1.03, 1);
    }

    40% {
        transform: scale(0.97, 1);
        -webkit-transform: scale(0.97, 1);
        -moz-transform: scale(0.97, 1);
        -o-transform: scale(0.97, 1);
        -ms-transform: scale(0.97, 1);
    }

    60% {
        transform: scale(1.01, 1);
        -webkit-transform: scale(1.01, 1);
        -moz-transform: scale(1.01, 1);
        -o-transform: scale(1.01, 1);
        -ms-transform: scale(1.01, 1);
    }

    80% {
        transform: scale(0.99, 1);
        -webkit-transform: scale(0.99, 1);
        -moz-transform: scale(0.99, 1);
        -o-transform: scale(0.99, 1);
        -ms-transform: scale(0.99, 1);
    }

    100% {
        transform: scale(1);
        -webkit-transform: scale(1);
        -moz-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
    }
}

h2 {
    margin: 0 !important;
    padding: 0 !important;
    /* line-height: 10px !important; */
    /* commented out for mobile version */
    letter-spacing: 2.5px !important;
    font-family: 'Audiowide' !important;
    font-weight: 700 !important;
    color: #fff !important;
}

h3 {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 11px !important;
    line-height: 11px !important;
    font-family: 'Open Sans' !important;
    font-weight: 400 !important;
    color: #777 !important;
}

h4 {
    /*margin: 0 0 5px 29px !important; */
    padding: 0 !important;
    font-size: 14px !important;
    line-height: 14px !important;
    font-family: 'Open Sans' !important;
    font-weight: 700 !important;
    color: #fff !important;
}

h5 {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 13px !important;
    line-height: 13px !important;
    font-family: 'Open Sans' !important;
    font-weight: 700 !important;
    color: #6d6d6d !important;
}

h6 {
    margin: 0 !important;
    padding: 0 !important;
    font-size: 13px !important;
    line-height: 13px !important;
    font-family: 'Open Sans' !important;
    font-weight: 700 !important;
    color: #f0f0f0 !important;
}

.screen {
    background-color: rgba(0, 0, 0, 0.7);
    padding: 0;
    height: 550px;
    width: 366px;
    position: absolute;
    left: 50%;
    /*top: 0;*/
    transform: translate(-50%, 0);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    margin: 30px 0;
    opacity: .9;
}

.header {
    display: inline-flex;
    margin: 0 0 40px 0;
}

input[type=checkbox] {
    visibility: hidden;
    margin: 0;
    padding: 0;
    z-index: 10;
}

label.main:before {
    position: absolute;
    top: 23px;
    left: 23px;
    font-family: FontAwesome;
    content: '\f0c9';
    color: #f6f6f6;
    font-size: 16px;
    line-height: 16px;
    cursor: grab;
    z-index: 10;
    transition: all 0.3s ease-in;
}

label.main:hover:before {
    color: #bbb;
}

.screen>#magicButton:checked~label.main:before {
    color: #ff564c;
    transition: all 0.3s ease-in;
    cursor: grabbing;
}

.coverImage {
    background-size: cover;
    width: 366px;
    height: 366px;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: 0% 0%;
    transition: all 0.3s ease-in;
}

.screen>#magicButton:checked~.coverImage {
    transform: scale(0.251, 0.251);
    left: 23px;
    top: 60px;
    transition: all 0.3s ease-in;
    border-radius: 20px;
}

.search:before {
    position: absolute;
    top: 23px;
    right: 23px;
    font-family: FontAwesome;
    content: '\f002';
    color: #959595;
    font-size: 16px;
    line-height: 16px;
    cursor: pointer;
    z-index: 10;
    transition: all 0.3s ease-in;
}

.search:hover:before {
    color: #bbb;
}

.bodyPlayer {
    position: absolute;
    top: 366px;
    left: 0;
    margin: 0;
    padding: 0;
    height: 183px;
    width: 366px;
    background: #111;
    transition: all 0.3s ease-in;
}

.screen>#magicButton:checked~.bodyPlayer {
    height: 470px;
    left: 0;
    top: 180px;
    transition: all 0.3s ease-in;
}

.list {
    border-spacing: 0px 2px;
    width: 342px;
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 378px;
    left: 12px;
    transform-origin: 0% 0%;
    transform: scale(0.1, 0.1);
    transition: all 0.3s ease-in;
}

.list tr {
    transform-origin: 0% 50%;
    height: 50px;
    text-align: center;
    background: #1d1d1d;
    text-indent: 18px;
}

.list tr:hover {
    background: #222;
    cursor: pointer;
}

.nr {
    width: 35px;
}

.title {
    text-align: left;
    text-indent: 15px;
    transition: all 0.11s ease-in;
}

.list tr:hover .title {
    padding-left: 5px;
    transition: all 0.11s ease-in;
}

.screen>#magicButton:checked~.list {
    top: 192px;
    left: 12px;
    opacity: 1;
    visibility: visible;
    transform: scale(1, 1);
    transition: all 0.3s ease-in;
}

.screen>#magicButton:checked~.footer {
    bottom: -60px;
    transition: all 0.3s ease-in;
}

.screen>#magicButton:checked~table tr:nth-child(1) {
    opacity: 1;
    animation: harlem 0.3s linear forwards;
    animation-delay: 0.185s;
}

.screen>#magicButton:checked~table tr:nth-child(2) {
    animation: harlem 0.3s linear forwards;
    animation-delay: 0.2s;
}

.screen>#magicButton:checked~table tr:nth-child(3) {
    animation: harlem 0.3s linear forwards;
    animation-delay: 0.215s;
}

.screen>#magicButton:checked~table tr:nth-child(4) {
    animation: harlem 0.3s linear forwards;
    animation-delay: 0.23s;
}

.screen>#magicButton:checked~table tr:nth-child(5) {
    animation: harlem 0.3s linear forwards;
    animation-delay: 0.245s;
}

.screen>#magicButton:checked~table tr:nth-child(6) {
    animation: harlem 0.3s linear forwards;
    animation-delay: 0.26s;
}

.screen>#magicButton:checked~table tr:nth-child(7) {
    animation: harlem 0.3s linear forwards;
    animation-delay: 0.275s;
}

.screen>#magicButton:checked~table tr:nth-child(8) {
    animation: harlem 0.3s linear forwards;
    animation-delay: 0.29s;
}

.screen>#magicButton:checked~table tr:nth-child(9) {
    animation: harlem 0.3s linear forwards;
    animation-delay: 0.305s;
}

label.zmr:before {
    font-family: FontAwesome;
    content: "\f08a";
    color: #777;
    font-size: 15px;
    line-height: 15px;
    cursor: pointer;
    z-index: 10;
    transition: all 0.15s linear;
}

td>#heart:checked~label.zmr:before {
    content: "\f004";
    color: #ff564c;
    transition: all 0.15s linear;
}

td>#heart1:checked~label.zmr:before {
    content: "\f004";
    color: #ff564c;
    transition: all 0.15s linear;
}

td>#heart2:checked~label.zmr:before {
    content: "\f004";
    color: #ff564c;
    transition: all 0.15s linear;
}

td>#heart3:checked~label.zmr:before {
    content: "\f004";
    color: #ff564c;
    transition: all 0.15s linear;
}

td>#heart4:checked~label.zmr:before {
    content: "\f004";
    color: #ff564c;
    transition: all 0.15s linear;
}

td>#heart5:checked~label.zmr:before {
    content: "\f004";
    color: #ff564c;
    transition: all 0.15s linear;
}

td>#heart6:checked~label.zmr:before {
    content: "\f004";
    color: #ff564c;
    transition: all 0.15s linear;
}

td>#heart7:checked~label.zmr:before {
    content: "\f004";
    color: #ff564c;
    transition: all 0.15s linear;
}

td>#heart8:checked~label.zmr:before {
    content: "\f004";
    color: #ff564c;
    transition: all 0.15s linear;
}

.bar {
    background-size: 100%;
    width: 320px;
    height: 21px;
    position: absolute;
    top: 407px;
    left: 23px;
    transform-origin: 0% 0%;
    transition: all 0.3s ease-in;
}

.screen>#magicButton:checked~.bar {
    top: 119px;
    left: 126px;
    transform: scale(0.6875, 0.6875);
    transition: all 0.3s ease-in;
}

.info {
    width: 157px;
    position: absolute;
    left: 104px;
    top: 440px;
    transition: all 0.3s ease-in;
    text-align: center;
}

.screen>#magicButton:checked~.info {
    top: 36px;
    left: 126px;
    text-align: left;
    transition: all 0.3s ease-in;
}

.screen>#magicButton:checked~.info h4 {
    margin-left: 0;
    font-size: 16px;
    color: #111111;
    transition: all 0.3s ease-in;
}

.player {
    background: none;
    text-align: center;
    font-family: FontAwesome;
    color: #fff;
    font-size: 26px;
    line-height: 55px;
    width: 80%;
    height: 35px;
    position: absolute;
    bottom: 35px;
    left: 48%;
    transform: translate(-50%, 0);
    z-index: 10;
    transition: all 0.3s ease-in;
    border-spacing: 0;
}

.player td {
    width: 72px;
}

.backward:before {
    content: "\f048";
    cursor: pointer;
    transition: all 0.15s linear;
}

.play:before {
    content: "\f04b";
    cursor: pointer;
    transition: all 0.15s linear;
}

.forward:before {
    content: "\f051";
    cursor: pointer;
    transition: all 0.15s linear;
}

.backward:hover:before {
    color: #bbb;
    transition: all 0.15s linear;
}

.play:hover:before {
    color: #bbb;
    transition: all 0.15s linear;
}

.forward:hover:before {
    color: #bbb;
    transition: all 0.15s linear;
}

#play:checked~label.play:before {
    content: "\f04c";
    transition: all 0.15s linear;
}

.screen>#magicButton:checked~.player {
    bottom: -25px;
    left: -44px;
    transform: scale(0.45, 0.45);
    transition: all 0.3s ease-in;
}

.shadow {
    background: rgba(17, 17, 17, 0.8);
    width: 366px;
    height: 100px;
    position: absolute;
    bottom: -12px;
    left: 0;
    box-shadow: 0px -15px 40px rgba(17, 17, 17, 0.8);
}

.love:before {
    content: "\f08a";
    cursor: pointer;
    transition: all 0.15s linear;
}

.shuffle:before {
    content: "\f074";
    cursor: pointer;
    transition: all 0.15s linear;
}

.repeat:before {
    content: "\f01e";
    cursor: pointer;
    transition: all 0.15s linear;
}

.options:before {
    content: "\f141";
    cursor: pointer;
    transition: all 0.15s linear;
}

.love:hover:before {
    color: #bbb;
    transition: all 0.15s linear;
}

.shuffle:hover:before {
    color: #bbb;
    transition: all 0.15s linear;
}

.repeat:hover:before {
    color: #bbb;
    transition: all 0.15s linear;
}

.options:hover:before {
    color: #bbb;
    transition: all 0.15s linear;
}

#love:checked~label.love:before {
    content: "\f004";
    color: #ff564c;
    transition: all 0.15s linear;
}

#shuffle:checked~label.shuffle:before {
    color: #ff564c;
    transition: all 0.15s linear;
}

#repeat:checked~label.repeat:before {
    color: #ff564c;
    transition: all 0.15s linear;
}

.shuffleVideo:before {
    content: "\f03d";
    cursor: pointer;
    transition: all 0.15s linear;
}

.shuffleVideo:hover:before {
    color: #bbb;
    transition: all 0.15s linear;
}

#shuffleVideo:checked~label.shuffleVideo:before {
    content: "\f4e2";
    color: #ff564c;
    transition: all 0.15s linear;
}

.current {
    position: absolute;
    right: 23px;
    bottom: -60px;
    transition: all 0.3s ease-in;
}

.screen>#magicButton:checked~.current {
    bottom: 17px;
    transition: all 0.3s ease-in;
}

audio {
    visibility: hidden;
}

/* ** ********************* */


html {
    overflow: scroll;
    overflow-x: hidden;
}


::-webkit-scrollbar {
    width: 0;
    /* Remove scrollbar space */
    background: transparent;
    /* Optional: just make scrollbar invisible */
}



.musicPlayerSong:hover {
    background-color: rgba(0, 0, 0, 0.5);
    /*border:#2d0431 solid 1px; */
}

.musicPlayerSong {
    color: rgb(47, 255, 68);
    font-family: "audiowide" !important;
}

.musicPlayerHeading {
    color: rgb(0, 229, 68);
    font-family: "audiowide" !important;
}

.fontAudio {
    font-family: "audiowide" !important;
}

.songlist {
    overflow-y: scroll;
    display: grid;
    max-height: 60%;
    scrollbar-width: none;
}

.songlistSong {
    display: -webkit-box;
}

#AudioSeekBar {
    background: linear-gradient(to right, #0839b3 0%, #3767e1 0%, #fff 0%, #fff 100%);
    border: solid 1px #82CFD0;
    border-radius: 8px;
    height: 10px;
    outline: none;
    transition: background 450ms ease-in;
    -webkit-appearance: media-slider;
}

.dropdown-header {
    padding: 10px !important;
}

.footerLayerAuthor {
    width: -moz-available
}

.fading-div {
    transition: opacity 5s;
}

.fading-div.visible {
    opacity: 1;
    transition: opacity 1s;
}

.fading-div.hidden {
    opacity: 0;
}